@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&amp;family=Raleway:wght@500;700&amp;display=swap");

body {
  position: relative;
  font-family: "Poppins", sans-serif;
  background: #fff;
  overflow-x: hidden;
}
#regForm .tab {
  display: none;
  margin: 0;
}
:root {
  --theme-color: #dd1920;
  --theme-secondary-color: #153b6b;
  --tabs-hover: #e31c25;
  --tabs-p: #49658a;
  --pec-name: #00142d;
}

p {
  font-size: 1.0625rem;
  font-family: "Poppins", sans-serif;
  color: #49658a;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
}
.section-padding {
  margin-top: 6rem;
}

.img-auto {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

a,
button {
  text-decoration: none !important;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

a:hover,
button:hover {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

dl,
ol,
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

figure {
  margin: 0;
}

.themeBtn {
  background-color: var(--theme-color);
  font-size: 1rem;
  border: 1px solid var(--theme-color);
  padding: 0.7rem 1.5rem;
  text-transform: capitalize;
  color: var(--white);
  transition: all 0.4s ease-in-out;
  /* display: block; */
  border-radius: 30px;
  text-align: center;
}

.themeBtn:hover {
  background-color: #fff;
  color: var(--theme-color);
}

.themeBtn2 {
  background-position: center;
  background-color: var(--gray);
}

.themeBtn2:hover {
  border-right-color: var(--gray);
  border-left-color: var(--gray);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
}

.aboutSec {
  position: relative;
  padding: 7em 0px;
}

.secHeading {
  margin-bottom: 20px;
}

.secHeading h2 {
  font-size: 2rem;
  color: var(--pec-name);
  margin: 0;
  text-transform: uppercase;
  font-weight: 700;
}

.secHeading h2 span {
  position: relative;
}

.secHeading h2 span img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120%;
  height: 120%;
}

/* css start */

header {
  position: absolute;
  width: 100%;
  top: 0;
  padding: 1rem 0;
  z-index: 9;
}

header ul {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0 2rem;
}

header ul li a {
  font-weight: 500;
  font-size: 0.935rem;
  color: #262728;
}

header ul li a span {
  font-weight: 700;
}

header ul li a i {
  margin-right: 0.5rem;
}

.heroSec {
  position: relative;
  padding: 10rem 0 3rem;
}
.heroSec {
  background: url("./assest/images/webp/banner.webp") bottom/cover no-repeat;
}

.heroSec .overlay {
  position: relative;
  width: 100%;
  inset: 0;
  display: flex;
  align-items: center;
}

.heroSec .content h4 {
  font-size: 1.5625rem;
  margin: 0;
  color: var(--theme-secondary-color);
}

.heroSec .content h2 {
  font-size: 4.1rem;
  color: var(--theme-color);
  margin: 15px 0 1.25rem;
  font-weight: 700;
}

.heroSec .content h2 span {
  display: block;
  font-size: 3.5rem;
  color: var(--theme-secondary-color);
}

.heroSec .content p {
  color: var(--tabs-p);
  font-size: 1.0625rem;
  font-weight: 400;
}

.heroSec .content form {
  margin-top: 2.5rem;
}

.heroSec .frm-grup-cta {
  width: 100%;
  height: 64px;
  font-size: 0.935rem;
  border-radius: 30px;
  border: 1px solid #d2d2d2;
  position: relative;
}

.heroSec .frm-grup-cta input {
  background-color: transparent !important;
  height: 64px;
  border: 0;
  box-shadow: none;
  padding: 0 25px;
  border-radius: 30px;
}

.heroSec .frm-grup-cta-btn {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  padding: 0;
}
.heroSec .frm-grup-cta-btn button {
  font-size: 1.125rem;
  padding: 0.8rem 3rem;
  font-weight: 500;
}
.heroSec .frm-grup-cta-btn input:focus {
  outline: none;
  box-shadow: none;
}

.logosSec {
  padding: 0;
}

.track-horizontal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200vw;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.logos {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.marquee-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
  /*margin-top: 6.4rem;*/
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
  background: var(--theme-color);
}

.marquee-logo-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.marquee-logo {
  width: 50px;
  height: 50px;
}

.marquee-grad {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: linear-gradient(to right, #dd1920, transparent, #dd1920);
  /*background: var(--theme-color);*/
}

.track-horizontal {
  white-space: nowrap;
  will-change: transform;
  animation: marquee-wrap 24s linear infinite;
  /* manipulate the speed of the marquee by changing "40s" line above*/
}

@keyframes marquee-wrap {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0%);
  }
}

.w-embed:after,
.w-embed:before {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}

.w-embed:after {
  clear: both;
}

.aboutImgCont {
  position: relative;
}

.aboutImgCont img {
  border-radius: 15px;
}

.aboutImgCont img:first-of-type {
  top: 0;
  left: -150px;
  z-index: 1;
  position: absolute;
}

.aboutImgCont img:last-of-type {
  margin-top: 120px;
}

.tagsCont {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tagsCont .tag {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.tagsCont .tag span {
  font-size: 3.6875rem;
  font-weight: 700;
  background: linear-gradient(#2aaab7, #1573ae);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tagsCont .tag p {
  font-size: 1rem;
  margin: 0;
  font-weight: 600;
  color: var(--theme-secondary-color);
}

/* hover-tabs */
.main-hdg h2 {
  font-size: 4.125rem;
  text-transform: capitalize;
  text-align: center;
}
.tabs-bg-img {
  position: absolute;
  bottom: -15px;
  left: -50px;
  z-index: 1;
}
.our-service-sec {
  background-color: #fbfbfb;
  padding: 100px 0px 0px;
}
.tp__header {
  font-size: 6.5625rem;
  line-height: 7.5rem;
}
.tp__header.on .anim-spec {
  transform: translateY(0);
}

.tp__list {
  margin-top: 1rem;
  display: flex;
}
.tp__list.on .text-anim,
.tp__list.on .tp__list__left__img {
  opacity: 1;
  transform: translateY(0);
}

.tp__list.on .list-anim,
.tp__list.on .list-anim-second {
  transform: translateY(0);
}

.tp__list__mobile {
  display: none;
}

.tp__list__mobile > * {
  padding: 0 !important;
}
.tp__list__left {
  width: 100%;
  position: relative;
  z-index: 2;
  padding-right: 5rem;
  padding-top: 1.5rem;
}
.tp__list__left__wrapper {
  width: 100%;
  transition: transform 5s ease-out;
  transform: scale(1.1);
}

.tp__list__left__img {
  position: relative;
  width: 100%;
  height: 100%;
  transition: height 0.5s cubic-bezier(0.21, 1.03, 0.7, 1),
    transform 0.8s cubic-bezier(0.21, 1.03, 0.7, 1), opacity 0.5s;
  transform: translateY(40%);
  opacity: 0.3;
  overflow: hidden;
  display: flex;
  z-index: 2;
  align-items: center;
}
.tp__list__left__img:hover .img-overflow {
  opacity: 0.8;
}

.tp__list__left__img.hide {
  transition: height 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  height: 0;
}

.tp__list__left__img.hide img {
  transform: translateZ(0);
}

.tp__list__left__img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s, filter 0.5s;
  transform: translateZ(0);
  backface-visibility: hidden;
  box-shadow: -1.754px 5.738px 120px 0px rgba(21, 59, 107, 0.07);
}

.tp__list__right__item {
  display: flex;
  padding: 1.5rem 0;
  flex-direction: column;
  cursor: pointer;
  transition: height 0.5s ease-in;
}

.tp__list__right__item._is-active .tp__list__right__item__header {
  transform: translateX(2.0833333333vw);
  transition: transform 0.5s ease-in-out;
}
.tp__list__right__item._is-active
  .tp__list__right__item__header
  .list-anim-second {
  width: 100%;
  transition: width 0.5s ease-out;
}
.tp__list__right__item._is-active .tp__list__right__item__desc {
  transform: translateX(2.0833333333vw);
  transition: transform 0.5s ease-in-out;
}
.tp__list__right__item._is-active
  .tp__list__right__item__desc
  .list-anim-second {
  width: 100%;
  transition: width 0.5s ease-out;
}
.tp__list__right__item__img {
  display: none;
  width: 100%;
  height: 0;
  margin-top: 1.5625rem;
  transition: height 0.8s cubic-bezier(0.21, 1.03, 0.7, 1);
  overflow: hidden;
  position: relative;
}
.tp__list__right__item__img a {
  display: grid;
  height: 100%;
  width: 60vh;
  color: #fff;
  text-decoration: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.tp__list__right__item__img a > * {
  align-self: center;
  justify-self: center;
}

.tp__list__right__item__img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  grid-area: 1/1/1/1;
}

.tp__list__right__item__img__text {
  grid-area: 1/1/1/1;
  border-bottom: 0.0625rem solid #fff;
  font-weight: 700;
}
.tp__list__right__item._is-active .list-anim-second {
  color: var(--tabs-hover);
  overflow: visible;
}
.tp__list__right__item__header {
  font-weight: 700;
  font-size: 2.75rem;
  line-height: 1.1em;
  transition: transform 0.5s ease-in-out;
  width: fit-content;
  position: relative;
}
.tp__list__right__item__desc {
  margin-top: 0.5rem;
  line-height: 1.2rem;
  transition: transform 0.5s ease-in-out;
  color: var(--tabs-p);
  font-size: 0.938rem;
}
.pinned-image {
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1rem;
  font-weight: 700;
}

.list-anim,
.text-anim {
  display: inline-block;
}

.list-anim {
  will-change: transform;
  transform: translateY(100%);
  color: var(--pec-name);
  opacity: 0.8;
}

.list-anim-second {
  transform: translateY(100%);
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  /* bottom: 0; */
  width: 0;
  white-space: nowrap;
}
.first-slider-text {
  transform: translateY(100%) !important;
  transition: transform 0.8s cubic-bezier(0.21, 1.03, 0.7, 1);
}

.swiper-slide {
  display: flex;
  align-items: center;
}

.char-svg {
  width: 100%;
}

.tp__list__left__img .char-svg:nth-child(2) {
  transform: translate3d(-100%, 60%, 0);
  opacity: 0;
}

.tp__list__left__img .char-svg:nth-child(3) {
  fill: url(#char-patternonly);
  opacity: 0;
}

.tp__list__left__img .char-svg:nth-child(4) {
  transform: translate3d(0, -160%, 0);
  opacity: 0;
}
/* .scrollmagic-pin-spacer {
    inset: auto;
    margin: 0px;
    display: flex;
    position: relative;
    box-sizing: content-box;
    min-height: 547px;
    height: auto;
} */

.list-anim {
  transition: transform 0.8s cubic-bezier(0.21, 1.03, 0.7, 1) 0.7s;
}
.list-anim-second {
  transition: transform 0.8s cubic-bezier(0.21, 1.03, 0.7, 1) 0.7s,
    width 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  white-space: nowrap;
}
.tp__list__right__item__desc .list-anim {
  transition: transform 0.8s cubic-bezier(0.21, 1.03, 0.7, 1) 0.8s;
}
.tp__list__right__item__header .list-anim1 {
  transition: transform 0.8s cubic-bezier(0.21, 1.03, 0.7, 1) 0.9s;
}
.tp__list__right__item__desc .list-anim2 {
  transition: transform 0.8s cubic-bezier(0.21, 1.03, 0.7, 1) 1s;
}
.list-anim-second-one {
  transition: transform 0.8s cubic-bezier(0.21, 1.03, 0.7, 1) 1.1s,
    width 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  white-space: nowrap;
}
.tp__list__right__item__desc .list-anim3 {
  transition: transform 0.8s cubic-bezier(0.21, 1.03, 0.7, 1) 1.2s;
}
.tp__list__right__item__header .list-anim4 {
  transition: transform 0.8s cubic-bezier(0.21, 1.03, 0.7, 1) 1.3s;
}
.list-anim-second-two {
  transition: transform 0.8s cubic-bezier(0.21, 1.03, 0.7, 1) 1.3s,
    width 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  white-space: nowrap;
}

/* end hover-tabs */

/* cta-sec */
.cta-sec {
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5rem 0;
  text-align: center;
  position: relative;
}
.cta-sec {
  background-image: url("./assest/images/webp/cta-bg.webp");
}
.cta-sec::before {
  content: "";
  position: absolute;
  background-image: url("./assest/images/webp/cta-left.webp");
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  width: 485px;
  height: 400px;
  left: 0;
  top: 21px;
}
.cta-sec::before {
  background-image: url("./assest/images/webp/cta-left.webp");
}
.cta-contains h1 {
  font-size: 2.75rem;
  color: #fff;
  font-weight: 400;
  margin-bottom: 1.5rem;
}
.cta-contains h1 span {
  font-weight: 700;
}
.cta-contains h2 {
  font-weight: 400;
  color: #fff;
  font-size: 1.875rem;
  margin-bottom: 2rem;
  position: relative;
}
.cta-contains a {
  position: relative;
  font-weight: 700;
  color: #fff;
}

.cta-contains h2 a::after {
  content: "";
  position: absolute;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: -5px;
  width: 256px;
  height: 7px;
  left: 0;
}
.cta-contains.visible h2 a::after {
  /* background-image: url(../images/webp/cta-text.webp); */
}
.cta-contains .themeBtn {
  padding: 0.8rem 2.3rem;
  font-weight: 600;
  border: 0;
}
.cta-contains .themeBtn:nth-child(2) {
  background-color: #fff;
  color: var(--tabs-hover);
}
/* cta-sec */
/* our-portfolio */
.our-portfolio {
  padding: 6rem 0;

  background-size: cover;
  background-position: center;
  width: 100%;
}
.our-portfolio.visible {
  /* background-image: url(../images/webp/portfolio-bg.webp); */
}
.main-hdg h2 span img {
  width: 107%;
  height: 80%;
  z-index: -1;
}
.main-hdg h2 span {
  z-index: 2;
  position: relative;
}
.portfolio-images {
  padding: 0.5rem 0;
}
.portfolio-images1 {
  margin-top: 2rem;
  box-shadow: -1.754px 5.738px 120px 0px rgb(21 59 107 / 7%);
  position: relative;
}
.portfolio-images1 .col-md-8 img,
.portfolio-images .col-md-4 img {
  height: 100%;
}
.image-after {
  position: relative;
  padding-right: 0 !important;
}
.image-after .tabs-bg-img {
  top: 2.5rem;
  left: 27rem;
  width: 21%;
}
.portfolio-images1 .col-md-4 {
  padding-right: 0.5rem;
}
.portfolio-images1 img {
  padding-bottom: 0.5rem;
}
.portfolio-images1 .col-md-8 {
  padding-right: 0.5rem;
}
.portfolio-images1:before {
  content: "";
  position: absolute;
  /* background: url(../images/webp/tabs-bg.webp) no-repeat;  */
  width: 65px;
  height: 92px;
  bottom: 8rem;
  right: -8rem;
}

/* our-portfolio */
/* Our Pricing */

.pec-box {
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  padding: 1.5rem 1.3rem;
  border-radius: 26px;
}
.pec-box {
  background-image: url("./assest/images/webp/pac-box.webp");
}
.our-pricing .row {
  position: relative;
}
.our-pricing .row:before {
  content: "";
  background: url("./assest/images/webp/pricing-before.webp") no-repeat;
  position: absolute;
  left: -6rem;
  top: -4rem;
  z-index: -1;
  width: 143px;
  height: 136px;
}
.img-new {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 100px;
}
.img-new h2 {
  background-color: var(--tabs-hover);
  font-size: 1rem;
  color: #fff;
  padding: 0.5rem;
  border-radius: 17px;
  margin-bottom: 0 !important;
}
.pec-name {
  padding: 0.5rem 0 1.5rem;
}
.pec-name h1 {
  font-size: 2rem;
  font-weight: 700;
  color: var(--pec-name);
}
.pec-name p {
  padding-right: 2rem;
  padding-bottom: 1rem;
}

.pec-list ul li {
  position: relative;
  margin-bottom: 1rem;
  line-height: 1.5rem;
  padding-left: 2rem;
  color: var(--tabs-p);
  font-size: 1.025rem;
}
.pec-list ul li::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  content: "\f058";
  position: absolute;
  font-family: "Font Awesome 5 Free";
  left: 0;
  color: var(--tabs-hover);
}
.price h3 {
  color: var(--pec-name);
  font-size: 42px;
  font-weight: 700;
}
.price span {
  color: #8ea2be;
  font-size: 1.125rem;
  font-weight: 300;
}
.price {
  padding-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.price .themeBtn {
  width: 100%;
  text-align: center;
  margin-top: 1rem;
  box-shadow: 0px 2px 43px 0px rgba(227, 28, 37, 0.2);
}
.our-pricing {
  padding: 6rem 0;
}
.our-pricing {
  position: relative;
}
.our-pricing:before {
}
.pec-box:hover {
  background-image: url("./assest/images/webp/pac-box-hover.webp");
}
.pec-box:hover .img-new h2,
.pec-box:hover .price .themeBtn {
  background-color: #fff;
  color: var(--tabs-hover);
}
.pec-box:hover .pec-name h1,
.pec-box:hover .pec-name p,
.pec-box:hover .pec-list ul li,
.pec-box:hover .price h3,
.pec-box:hover li::before {
  color: #fff;
}
.pec-box:hover .pec-name img {
  filter: brightness(0) invert(1);
}
.budget {
  background: #000000;
  padding: 40px 0;
}
.combo-list-style ul {
  display: block;
  color: #fff;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  font-family: "Poppins", sans-serif;
}
.top-packages h1 {
  font-size: 40px;
  color: #fff;
  text-transform: uppercase;
  display: block;
  margin-bottom: 5px;
  line-height: normal;
  font-family: "Ubuntu", sans-serif;
  font-weight: 600;
}
.combo-list-style ul li {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  padding-left: 30px;
  position: relative;
  margin-bottom: 25px;
}
.combo-list-style ul li i {
  color: #fff;
  font-size: 8px;
  padding-right: 10px;
}
.combo-list-style ul li h6 {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 8px;
  text-transform: uppercase;
}
.combo-list-style ul li {
  font-size: 13px;
  color: #fff;
  margin-bottom: 0px;
  border-bottom: 1px solid rgb(110 110 110 / 87%);
  padding: 4px 0;
  width: 100%;
}
.budgetList .combo-list-style {
  font-size: 0px;
  display: block;
}
.budgetList .combo-list-style ul li {
  display: inline-block;
  vertical-align: top;
  padding-right: 20px;
}
.budgetList .last {
  padding-right: 0px;
}
.budget .btn:before {
  border-color: #fff;
}
.budget .btn:hover:before {
  border-color: #d3071a;
}
.budget .btn:after {
  border-color: #fff;
}
.mn-h1 span {
  font-size: 30px;
  color: #fff;
  text-transform: uppercase;
  display: block;
  margin-bottom: 5px;
  line-height: normal;
  font-weight: 700;
}
.mn-h1 {
  font-size: 20px;
  color: #ffffff;
  line-height: 45px;
  font-weight: 600;
}
.budget .trus_piolet {
  justify-content: center;
}
.budget .trus_piolet a img {
  right: 0;
}
.budget .trus_piolet .bt-red {
  margin-top: 70px;
}
.break_col img {
  position: relative;
  margin: auto;
}
.final a.order-package {
  padding: 15px 50px;
  width: initial;
  background: #ea0122;
  display: inline-block;
  border-radius: 10px;
  color: #fff;
}
.final {
  text-align: center;
}
 .slick-dots {
  margin: 30px 0 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -50px;
}
.slick-dots li {
  display: flex;
  line-height: 1;
  margin: 0 4px;
}
.slick-dots li button {
  text-indent: -9999px;
  padding: 0;
  -webkit-box-shadow: none;
  -khtml-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  z-index: 1;
  overflow: visible !important;
  width: 24px;
  height: 8px;
  -webkit-border-radius: 8px;
  -khtml-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
  background-color: #d6dcdb;
  border: transparent;
}
.slick-dots li.slick-active button {
  background-color: #ea0122;
}

.our-pricing .slick-list .col-md-4 {
  flex: 0 0 100%;
  max-width: 100%;
}

/* Our Pricing */

/* happy-clients */
.happy-clients {
  padding: 6rem 0;

  background-position: center;
  background-size: cover;
}
.happy-clients {
  background-image: url("./assest/images/webp/testimonials-bg.webp");
}
.testi-content-rpr {
  margin: auto;
}
.testi-content span {
  position: relative;
  z-index: 2;
}
.testi-content {
  text-align: center;
}
.testi-content h1 {
  color: #fff;
  font-size: 4.125rem;
  font-weight: 700;
}
.happy-text {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.main-testi {
  margin-top: 2.5rem;
  position: relative;
}
.main-testi img {
  margin-bottom: 1rem;
}
.main-testi p {
  color: #fff;
  padding: 0 3.3rem;
  line-height: 1.9rem;
  font-size: 1.313rem;
}
.qouit-bg {
  position: absolute;
  left: 0;
  top: 7rem;
  margin: auto;
  right: 0;
}
.main-testi h4 {
  font-size: 1.375rem;
  color: #fff;
  margin-top: 2rem;
}
.testi-content .owl-carousel .owl-item img {
  display: block;
  width: auto;
  margin: auto auto 1rem;
}
.testi-content .owl-theme .owl-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 7rem;
  bottom: 0;
  left: -1rem;
  right: -1rem;
}
.testi-content .owl-theme .owl-nav [class*="owl-"] {
  background-color: #fff;
  border-radius: 50%;
  padding: 0.5rem;
  height: 35px;
  width: 35px;
  color: var(--tabs-hover);
  margin: 0;
}
/* happy-clients */

/* How it works */

.how_works {
  padding: 5rem 0;
  background: #fbfbfb;
}
.processtime {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #060606;
  font-weight: 600;
}
.processtime svg {
  width: 36px;
  height: 36px;
  margin-right: 10px;
  fill: #dd1920;
}
.boxes {
  text-align: center;
  margin-top: 5rem;
  padding: 25px 18px;
  border-radius: 15px;
  transition: all 0.3s ease-out;
}
.boxes .icons {
  width: 142px;
  height: 142px;
  margin: auto auto 20px;
  background: #f2f3f7;
  border-radius: 100%;
}
.boxes .icons svg {
  width: 80px;
}
.boxes .icons svg path,
.boxes .icons svg circle {
  /* fill: url(#svg-gradient-primary); */
}
.boxes p {
  color: #060606;
}
.boxes:hover {
  background: #de182a;
}
.boxes:hover * {
  color: #fff;
}

/* Contact us */

.contactsec {
  padding: 5rem 0;
}
.contactsec .main-hdg h2 {
  text-align: left;
}
.footerform .form-control {
  border-radius: 20px;
  padding: 15px 20px;
  height: initial;
}
.footerform textarea.form-control {
  resize: none;
}
.pix-fancy-mockup {
  position: relative;
  display: block;
  pointer-events: none;
  will-change: transform;
  transform-style: preserve-3d;
  perspective-origin: 50% 100% !important;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  perspective: 1110px;
  perspective-origin: 50% 100%;
  margin-top: 3rem;
}
.pix-fancy-mockup .pix-fancy-content {
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  width: 86%;
  left: 0px;
  top: 0px;
  right: 0px;
  display: block;
  height: 92%;
  margin-top: 3%;
  z-index: 2;
  margin-right: auto;
  margin-left: auto;
  transform-style: preserve-3d;
}
.pix-fancy-mockup .pix-fancy-content img {
  will-change: transform;
  transform-style: preserve-3d;
  vertical-align: middle;
  z-index: 2;
  -o-object-fit: cover;
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  left: 0;
  top: -2px;
  position: absolute;
}
.pix-fancy-mockup .pix-fancy-device-img {
  will-change: transform;
  transform-style: preserve-3d;
  vertical-align: middle;
  display: block;
  max-height: 100%;
  max-width: 100%;
  margin-bottom: 0;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 12;
}
.footerform {
  margin-top: 2rem;
  padding-right: 2rem;
}
/* footer */

footer {
  background-color: #134068;
  padding: 20px 0;
}

footer p {
  color: #fff;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
}

footer span a {
  margin-left: 10px;
  color: #fff;
}

footer span {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}

footer span a:nth-child(1) {
  margin-left: 0;
  margin-right: 10px;
}
.disclemar {
  border-top: 1px solid #ccc;
  padding: 20px 0 0;
  text-align: center;
  margin-top: 15px;
}
.disclemar strong {
  font-size: 16px;
  margin-bottom: 10px;
}
.disclemar p {
  color: #fff;
}

/* footer */

/* popup from */

.form-error {
  display: none;
}
input.error {
  border-color: #dd3b18 !important;
}
#entry-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 22;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.entry-box {
  background: #000;
  text-align: center;
  padding-top: 0;
  padding-bottom: 30px;
  /* width: 30%; */
  width: 100% !important;
  margin: 0 auto;
  border-radius: 20px;
  position: relative;
  /* overflow: hidden; */
  pointer-events: auto;
  border: 1px solid #fff;
}
.entry-box h1 {
  text-shadow: 0 5px 10px rgba(0, 0, 0, 0.35);
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  line-height: 60px;
}
.entry-box h1 span {
  color: #ffd800;
}
.entry-left {
  text-align: center;
  background-image: -moz-linear-gradient(90deg, #ef0d33 0, #ff1010 100%);
  background-image: -ms-linear-gradient(90deg, #ef0d33 0, #ff1010 100%);
  background-color: transparent;
}
.entry-box h1 {
  text-shadow: 0 5px 10px rgba(0, 0, 0, 0.35);
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  line-height: 60px;
}
.entry-right .text {
  position: relative;
  margin: 15px 0 25px;
}
.entry-right .text h2 {
  font-size: 22px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0;
}
.entry-right .text h2 span {
  color: var(--theme-color);
}
.entry-right .text:before {
  content: "";
  width: 6px;
  height: 50px;
  background: var(--theme-color);
  border-radius: 20%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}
.form-control.error {
  border-color: red !important;
}
label.error {
  display: none !important;
}
.entry-right .text strong {
  text-transform: uppercase;
}
.entry-right .form-control:focus {
  border-color: #e2001b;
  box-shadow: 0 0 5px 0 #dee2e4;
  background-color: #fff;
  box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.2);
}
.entry-right input,
.entry-right textarea {
  border-radius: 5px;
  height: 51px;
  padding-left: 20px;
  border: 1px solid #e3e3e3;
  box-shadow: none;
  background: #fafafa;
  margin: 5px 0;
}
.entry-right textarea {
  height: 110px;
}
.entry-right input[type="email"],
.leaving-box input[type="email"] {
  background-color: #fff !important;
  color: #555 !important;
}
.entry-right .intl-tel-input {
  width: 100%;
}
.entry-right .intl-tel-input.separate-dial-code .selected-dial-code {
  padding-left: 0;
}
.entery-submit {
  width: 60%;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  border: none !important;
  padding: 10px;
  cursor: pointer;
  border-radius: 30px;
  background-color: var(--theme-color) !important;
  justify-content: center;
}
.entry-right a {
  height: 60px;
  line-height: 60px;
  display: block;
  width: 100%;
  background-color: #ffd800;
  border-radius: 50px;
  text-transform: uppercase;
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.entry-right a i {
  margin-left: 6px;
}
.entry-right p {
  color: #fff;
  font-size: 12px;
  margin: 0;
}
.entry-right .form-group {
  margin-bottom: 10px;
}
a#entry-box_close {
  position: absolute;
  right: 5px;
  background-color: transparent;
  width: 40px;
  height: 40px;
  border-radius: 50pc;
  color: #fff;
  font-size: 29px;
  top: 15px;
  z-index: 9;
}
#entry-popup .col-sm-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

/* popup from End*/

#regForm .tab {
  display: none;
  margin: 0;
}

footer span a:hover {
  color: #3da3b1;
}
.close {
  margin-top: 10px;
  font-size: 32px;
  color: #fff;
  opacity: 1;
}

.bnnr-discount {
  padding: 20px 0px 0px;
  display: block;
  font-weight: 600;
}

.bnnr-discount span {
  color: #d60028;
}
/* .about-sec-buttons{
    display: none;
} */

.about-sec-buttons .themeBtn {
  padding: 10px 40px;
}
.banner-mob-img {
  display: none !important;
}

.navbar-brand img {
  width: 75%;
}

.about-sec-buttons {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.combo-sec .price h3 {
  color: #fff;
  font-size: 60px;
}
.final.price span {
  color: #fff;
  font-size: 40px;
  font-weight: 500;
}

.price h2 {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  font-size: 20px;
  color: #c4c4c4;
  font-weight: 600;
  line-height: 33px;
}

.price h2 sub:before {
  content: "";
  background: #010101;
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  top: -2px;
  margin: auto;
  left: 0;
  right: 0;
  transform: rotate(-9deg);
  z-index: 2;
}

.price h2 sub {
  position: relative;
}

.pec-box:hover .themeBtn {
  background-color: #fff;
  color: #000;
}
.pkg_btn {
  display: block;
}
.pricing_bx .big-price {
  content: "";
  position: absolute;
  top: 13rem;
  left: 13.5rem;
  font-weight: 600;
  font-size: 5rem;
  transform: rotate(5deg);
  color: #fff;
}
.cookie-bar {
  position: fixed;
  bottom: 0;
  padding: 12px 15px;
  width: 100%;
  z-index: 15;
  background-size: cover;
  text-align: left;
  z-index: 99999;
  background: #050b33;
  display: none;
}

.cookie-bar .col-nopadd {
  padding-left: 0;
  padding-right: 0;
}

.cookie-bar p {
  display: inline-block;
  margin-bottom: 0;
  color: #fff;
  font-size: calc(11px + (13 - 11) * (100vw - 320px) / (1920 - 320));
  line-height: 25px;
  margin-right: 10px;
}

.cookie-bar .setcokies {
  color: #fff;
  display: inline-block;
}
.privicylink a {
  height: auto;
  line-height: normal;
  display: inherit;
  font-size: 12px;
  width: 100%;
  background-color: transparent;
  border-radius: 0;
  color: #fff;
  font-weight: 600;
}
.cookie-bar .cookie-btn {
  color: #fff;
  background: #e00030;
  padding: 3px 10px 3px 6px;
  border-radius: 0;
  font-weight: 700;
  font-size: calc(11px + (13 - 11) * (100vw - 320px) / (1920 - 320));
  text-decoration: none;
  text-transform: uppercase;
  margin-left: 0;
}

.cookie-bar .cookie-btn-3 {
  border: 1px solid #fff;
  padding: 2px 4px;
  background: #36454f;
  border-radius: 2px;
  color: #fff;
}
.setcokies a:hover {
  color: #fff;
}

.pkg_list {
  overflow-y: auto;
  height: 400px;
  
}
.pkg_list::-webkit-scrollbar {
    width: 10px;

  }
  
  /* Track */
  .pkg_list::-webkit-scrollbar-track {
    background: transparent; 
  }
   
  /* Handle */
  .pkg_list::-webkit-scrollbar-thumb {
    background: #dbd7d7; 
    border-radius: 6px;
  }
  
  /* Handle on hover */
  .pkg_list::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 6px; 
  }

.slider_happy_clients .slick-prev::before, .slider_happy_clients .slick-next::before{
  font-size: 34px !important;
}

.react-responsive-modal-modal{
    max-width: 900px !important;
    width: 35% !important;
    background-color: transparent !important;    
    height: 100%;
    box-shadow: none !important;
}


.modal{
    width: 100% !important;
    display: block !important;
}
.modal-dialog{
    margin: 0px !important;
    width: 100% !important;

}
.react-responsive-modal-closeButton{
    display: none !important;
}
.slider_section .slick-prev, .slider_section .slick-next{
     display: none !important;
}
.slider_section .slick-dots{
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 4px;
}


.price_tabs{
   width: 100%;
   display: flex;
   justify-content: center;
   gap: 20px;
   
}
.price_tabs button{
  width: 120px !important;
}


.pricing_carosaul .slick-dots{
   margin-bottom: -20px;
   display: flex !important;
   justify-content: center !important;
   align-items: center !important;
}

.active_tab{
  background-color: white;
  color: red;
}












/* Responsivness */

/* hover-tabs */
@media (max-width: 1600px) {
  .char-svg {
    width: 95%;
  }
}
@media (max-width: 1480px) {
  .cta-contains h1 {
    font-size: 2.05rem;
  }
}

@media (max-width: 1370px) {
  .tp__list__left__img {
    width: 100%;
  }
  .heroSec {
    background-position: top center;
  }
  .marquee-wrap {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }
  .marquee-logo {
    width: 40px;
    height: 35px;
  }
}
@media (max-width: 1200px) and (min-width: 801px) {
  .tp__header {
    font-size: 4.375rem;
  }
  .tp__list__right__item__header {
    font-size: 2.5rem;
  }

  .aboutImgCont img:first-of-type {
    left: -60px;
  }
  .tagsCont .tag {
    display: block;
  }
  .portfolio-images1:before {
    right: -3rem;
  }
  .pec-list ul li {
    font-size: 0.925rem;
    line-height: 1.4rem;
  }
  .cta-contains h1 {
    font-size: 1.7rem;
  }
  .cta-sec {
    padding: 2rem 0;
  }
  .cta-sec::before {
    width: 260px;
    height: 270px;
    background-size: 100%;
  }
  section {
    padding: 3rem 0 !important;
  }
  section.logosSec {
    padding: 0 !important;
  }
  .testi-content h1 {
    font-size: 2.425rem;
  }
  .main-hdg h2 {
    font-size: 2.525rem;
  }
  .heroSec .content h2 span {
    font-size: 3rem;
  }
}

@media (max-width: 1080px) {
  .portfolio-images1:before {
    display: none;
  }
  section {
    padding: 3rem 0 !important;
  }
  section.logosSec {
    padding: 0 !important;
  }
  .pricing_carosaul .slick-dots{
    margin-bottom: 5px !important;
   }
}

@media (max-width: 991px) {
  footer p {
    text-align: center;
  }

  .container {
    max-width: 90%;
  }
  .heroSec .content h2 {
    font-size: 3.1rem;
  }
  .aboutImgCont {
    text-align: right;
  }
  .aboutImgCont img:first-of-type {
    left: 0;
  }
  .tabs-bg-img {
    display: none;
  }
  .secHeading h2 {
    font-size: 2rem;
  }
  .marquee-wrap {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .marquee-logo {
    height: auto;
  }
  .heroSec .content h2 {
    font-size: 2.7rem;
  }
  .heroSec .content h2 span {
    font-size: 2.3rem;
  }
  .section-padding {
    margin-top: 0;
  }
  .cta-sec::before {
    display: none;
  }
  .break_col img {
    max-width: 100%;
  }
  .processtime {
    font-size: 20px;
  }
  .pinned-image {
    height: 45vh;
  }

  .combo-sec {
    padding: 0px !important;
    margin: 0px 0px 40px;
  }
  .top-packages .bg-secondary {
    padding: 20px 30px 0px;
  }
  .combo-list-style ul li {
    padding: 5px 0;
    font-size: 13px;
  }
  .navbar-brand img {
    width: 70%;
  }
}

@media (max-width: 800px) {
  .pricing_bx .big-price {
    top: 11rem;
    left: 9.5rem;
    font-size: 3rem;
  }
  .tp {
    padding: 0 8.3333333333vw;
  }
  .tp__header {
    font-size: 2.8125rem;
    margin: 0;
    line-height: 3.375rem;
  }
  .tp__list__mobile {
    display: flex;
    justify-content: space-between;
  }
  .tp__list__left {
    width: 70%;
  }
  .tp__list__left {
    display: none;
  }
  .tp__list__right {
    margin-top: 3.125rem;
  }
  .tp__list__right__item {
    padding-bottom: 1.6rem;
    padding-top: 0;
  }
  .tp__list__right__item._is-active .tp__list__right__item__header {
    transform: translateX(0);
  }
  .tp__list__right__item._is-active
    .tp__list__right__item__header
    .list-anim-second {
    width: 0;
  }
  .tp__list__right__item._is-active .tp__list__right__item__desc {
    transform: translateX(0);
  }
  .tp__list__right__item._is-active
    .tp__list__right__item__desc
    .list-anim-second {
    width: 0;
  }
  .aboutImgCont img:first-of-type {
    display: none;
  }
  .portfolio-images1 img {
    width: 90% !important;
    margin: 0px auto;
  }

  .testi-content .owl-carousel .owl-item img {
    display: none;
  }
  .qouit-bg {
    display: none;
  }
  .heroSec {
    background: #fefefe;
    background-image: none;
  }
  .happy-clients {
    background: #b90c12;
    background-image: none;
  }

  .tp__list__right__item__img {
    display: block;
  }
  .tp__list__right__item__header {
    font-size: 1.875rem;
  }
  .list-anim {
    opacity: 1;
  }

  .tagsCont {
    display: block;
  }
  .tagsCont .tag span {
    font-size: 2.2875rem;
  }
  .aboutSec .secHeading h2 {
    font-size: 1.5rem;
  }
  .aboutImgCont img:first-of-type {
    position: relative;
  }
  .aboutImgCont img:last-of-type {
    margin-top: 10px;
  }
  .cta-contains h1 {
    font-size: 1.55rem;
  }
  .cta-contains h2 {
    font-size: 1.475rem;
  }
  .pec-name h1 {
    font-size: 1.6rem;
  }
  .pec-list ul li {
    font-size: 1.025rem;
    line-height: normal;
  }
  html {
    font-size: 14px;
  }
  .entry-box {
    width: auto;
    margin: 0px auto;
  }
  .tagsCont {
    display: flex;
  }
  .contactsec {
    overflow: hidden;
  }
  .react-responsive-modal-modal{
    width: 60% !important;
  }
  
}

@media (max-width: 767px) {
  .navbar-brand img {
    width: 30%;
  }
}

@media (max-width: 767px) {
  .combo-list-style ul li {
    margin-right: 0px;
    font-size: 12px;
  }
  .our-service-sec .secHeading h2 {
    font-size: 36px;
  }
  .entery-submit {
    width: auto;
  }
  .entry-right .text:before {
    display: none;
  }
  .tp__list__right__item._is-active .tp__list__right__item__img {
    height: 26.6875rem;
  }
  .aboutImgCont {
    margin-bottom: 1rem;
  }
  .aboutSec {
    text-align: center;
  }
  .footerform {
    margin-bottom: 2rem;
  }
  .contactsec {
    text-align: center;
  }
  .contactsec .main-hdg h2 {
    text-align: center;
  }
  .testi-content h1 {
    font-size: 2.025rem;
  }
  .happy-text {
    max-width: 100%;
  }
  .tp__list__right {
    text-align: center;
  }
  .tp__list__right__item__header {
    margin: auto;
  }
  .tp {
    padding: 0;
  }
  .heroSec {
    padding: 10rem 0 2rem;
    background-position: top right;
    text-align: center;
  }
  .main-form {
    margin-bottom: 1.5rem;
  }
  .heroSec .content h2 {
    margin-top: 0;
  }
  header ul li:first-child a {
    font-size: 0;
  }
  header ul li:first-child a i {
    font-size: 20px;
    background-color: var(--theme-color);
    color: #fff;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  header ul li {
    display: flex;
    align-items: center;
  }
  .aboutImgCont img:last-of-type {
    display: none;
  }
  .aboutImgCont {
    text-align: center;
  }
  footer {
    text-align: center;
  }
  footer .text-right {
    text-align: center !important;
  }
  footer img {
    margin: 10px auto;
  }
  .boxes {
    margin-top: 10px;
  }
  .cta-contains h2 a::after {
    background-size: cover;
  }
  .cta-contains h2 a::after {
    width: 100%;
  }
  .main-form {
    padding-bottom: 4rem;
  }
  .heroSec .frm-grup-cta-btn {
    position: relative;
  }
  .our-service-sec {
    padding-top: 0;
  }
  .tp__list__right__item__img a .tp__list__right__item__img__text {
    display: none;
  }
  .testi-content-rpr {
    padding: 0;
  }
  .main-testi p {
    font-size: 1.113rem;
    padding: 0 1.6rem;
  }
  .footerform {
    padding-right: 0;
  }
  .portfolio-images1 .col-md-4,
  .portfolio-images1 .col-md-8 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .img-new {
    height: auto;
  }
  #entry-popup .container {
    max-width: 100%;
  }
  .container {
    max-width: 100%;
  }

  .navbar-brand {
    margin: 0px;
    text-align: center;
  }
  header ul {
    justify-content: end;
    gap: 0 0rem;
    margin-top: 5px;
  }

  .pricing_bx .big-price {
    top: 16rem;
    left: 17.5rem;
    font-size: 4rem;
  }
}

@media (max-width:680px) {
  .slick-next{
    right: 5px !important;
 }
 .slick-prev{
  left: -10px !important;
 }
 .pricing_carosaul .slick-dots{
  margin-bottom: 6px !important;
 }
}

@media (max-width: 575px) {
  .navbar-brand img {
    width: 30%;
  }
  header ul {
    justify-content: center;
  }
  /* header .row *{ width: initial; } */
  .cta-contains h1 {
    margin-bottom: 0;
  }
  .tagsCont {
    display: block;
  }
  .tagsCont .tag {
    justify-content: center;
  }
  .qouit-bg {
    max-width: 100%;
  }
  .entry-box h1 {
    font-size: 18px;
  }
  .entry-right .text h2 {
    font-size: 20px;
    padding: 0 0px;
  }
  header .row {
    justify-content: center !important;
    align-items: center;
  }
  .heroSec {
    padding: 13rem 0 5rem;
  }
  .marquee-logo {
    width: 28px;
  }
  .cta-contains a {
    display: inline-block;
  }

  /*banner-mobile-design*/
  .heroSec .content h2 {
    text-align: left;
    font-size: 24px;
    margin-bottom: 5px;
  }
  .heroSec .content h2 span {
    font-size: 20px;
  }
  .heroSec .content p {
    text-align: left;
    font-size: 10px;
    font-weight: 500;
  }
  .heroSec .content .bnnr-discount {
    font-size: 14px;
    text-align: left;
    width: 100%;
    padding-top: 0px;
    margin-top: 10px;
  }
  .heroSec .content h4 {
    font-size: 16px;
    text-align: left;
  }
  .heroSec .frm-grup-cta input {
    height: 45px;
    padding: 0 15px;
  }
  .heroSec .content form {
    width: 300px;
    margin: 10px 0px 0px;
  }
  .heroSec .frm-grup-cta {
    width: 80%;
    height: 45px;
    margin-top: 15px;
    text-align: center;
  }
  .heroSec .frm-grup-cta-btn button {
    padding: 6px 28px;
  }
  .heroSec .content form {
    width: 350px;
  }
  .heroSec .frm-grup-cta-btn {
    top: 30px;
  }
  .bnnr-logos-col {
    position: relative;
    height: 100%;
  }
  /* .logo-wrap-disable {
    display: none !important;
  } */
  .banner-mob-img {
    display: inline-block !important;
  }
  .logo_img{
    width: 140px !important;
  }
  .carosal_section{
    width: 90%;
    margin: 0 auto;
  }
  .slick-next{
     right: -15px !important;
  }
  .react-responsive-modal-modal{
    width: 90% !important;
  }
}

@media (max-width: 500px) {
  .pricing_bx .big-price {
    left: 14.5rem;
  }
  .heroSec .content form {
    width: 300px;
  }
  .entry-right .text h2 br {
    display: none;
  }
  .about-sec-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-direction: unset;
    flex-wrap: wrap;
    align-items: center;
  }
  .tp__list__right__item._is-active .tp__list__right__item__img {
    height: 310px;
  }
  .tp__list__right__item__img a {
    height: 100%;
    width: 100%;
  }

  .cta-sec {
    background-image: none;
    background: #000 !important;
  }

  .packagebox .img-new {
    display: none;
  }

  .heroSec .frm-grup-cta-btn button {
    padding: 5px 20px;
    font-size: 14px;
  }
  .slick-prev{
    left: -25px !important;
   }
   .main-privacy {
    padding: 7rem 0 3rem !important;
}
}
@media (max-width:426px) {
  .pricing_bx .big-price{
    top: 14rem;
    left: 12.25rem;
  }
}

@media (max-width: 400px) {
  .heroSec .content form {
    width: 300px;
  }
  .heroSec .frm-grup-cta {
    width: 70%;
  }
  .pricing_bx .big-price {
    left: 11.5rem;
    top: 12rem;
    font-size: 3rem;
  }
}

@media (max-width: 420px) {
  .tp__list__right__item._is-active .tp__list__right__item__img {
    height: 290px;
  }
}

/* ends-hover-yabs */

/* responsive.css */


.main-privacy {
  padding-top: 10rem;
  padding-bottom: 5rem;
}